import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
import { FormularioContatoComponent } from './components/FormularioContatoComponent'

function FormularioContatoApp() {
    return (
        <div className="app-container">
            <div className="container body-content app-content">
                <FormularioContatoComponent />
            </div>
        </div>

    );
}

ReactDOM.render(<FormularioContatoApp />, document.getElementById('formulario-contato-app'));
