import React, { Component, Fragment } from 'react';
import axios from 'axios';

export class FormularioContatoComponent extends Component {
    state = {
        nome: "",
        email: "",
        mensagem: "",
        isSent: false
    }

    onChangeNome(e) {
        console.log("Alterando nome");
        this.setState({ nome: e.target.value });
    }

    onChangeEmail(e) {
        console.log("Alterando nome");
        this.setState({ email: e.target.value });
    }

    onChangeAssunto(e) {
        this.setState({ assunto: e.target.value });
    }

    onChangeMensagem(e) {
        this.setState({ mensagem: e.target.value });
    }

    enviarFormulario() {
        axios.post('./Contato/SendMessage', this.state).then((resp) => {
            this.setState({ isSent: true });
        })
        console.log(this.state);
    }

    render() {
        return (
            <Fragment>
                <div className="row form-contato-row">
                    <input type="text" className="form-control input-sm max-width form-contato-field"
                        placeholder="Nome*" value={this.state.nome} onChange={this.onChangeNome} />
                </div>
                <div className="row form-contato-row">
                    <input type="email" className="form-control input-sm max-width form-contato-field"
                        placeholder="E-mail *" value={this.state.email} onChange={this.onChangeEmail} />
                </div>
                <div className="row form-contato-row">
                    <textarea className="form-control input-sm max-width campo-mensagem form-contato-field"
                        placeholder="Mensagem" value={this.state.mensagem} onChange={this.onChangeMensagem} />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-enviar-mensagem float-right"
                            onClick={this.enviarFormulario}>Enviar</button>
                    </div>
                </div>

                {this.state.isSent && (
                    <div className="row">
                        <hr />
                        <div className="col-md-12">
                            <p className="text text-success">Mensagem enviada com sucesso</p>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}